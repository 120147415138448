.container {
    width: 100%;
    height: 20vh;
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    z-index: inherit;
  }
  
  .curve {
    height: 77vh;
    position: relative;
    z-index: 2;
    background: linear-gradient(#2186F2 ,#4E36E2, #4731d6);
    overflow: hidden;
    /* background-color: red; */
  }
  
  .white_curve {
    height: 100vh;
    /* border-radius:50%; */
    z-index: 1;
    width: 150%;
  
    /* margin: auto; */
    margin-top: 200px;
    margin-left: -25%;
    position: absolute;
    background-color: rgb(255, 255, 255);
    /* background-color: red; */
    border-top-right-radius: 50%;
    border-top-left-radius: 50%;
  }
  
  
  .inner_container {
    /* width: 100%; */
    height: 20vh;
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    bottom: 10px;
    /* background-color: rgb(36, 23, 23); */
  }
  
  
  .container img {
    width: 700px;
    display: block;
    /* background-color: red; */
  }
  
  .inner_container img {
    width: 550px;
    display: block;
  }
  
  @media (max-width:768px) {
    .container img {
      width: 250px;
      display: block;
      /* background-color: red; */
    }
  
    .inner_container img {
      width: 200px;
      display: block;
    }
  
    .inner_container {
      bottom: 4px;
    }
  
  }