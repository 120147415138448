* {
  margin: 0;
  padding: 0;
  font-family: 'Poppins', sans-serif;
  box-sizing: border-box;
  /* transition: 0.3s; */
  text-decoration: none;
  color: inherit;
  scroll-behavior: smooth;
}

::-webkit-scrollbar {
  position: relative;
  right: 5px;
  width: 5px;
}

::-webkit-scrollbar-track {
  background: #F2F2F2;
}

::-webkit-scrollbar-thumb {
  background: #2186F2;
  border-radius: 8px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #555;
}