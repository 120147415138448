.hero_section_main {
    display: flex;
    flex-direction: column;
    justify-content: center;
    /* height: 100vh; */
    color: white;
    position: relative;
    align-items: center;
    background: linear-gradient(#2186F2, #2186F2, #2186F2);
    padding-top: 100px;
}

.hero_section_content {
    font-family: sans-serif !important;
    font-size: 3.6rem;
    font-weight: bold;
    text-align: center;
    font-family: 'Inter';
    padding: 10px 20px;
}

.hero_section_content2 {
    padding: 30px 0 60px 0;
    width: 724px;
    /* background-color: red; */
    font-size: 1.5rem;
    font-weight: 500;
    /* letter-spacing: 0.6px; */
    text-align: justify;
    line-height: 35px;
    /* font-size: 18px; */
}

.hero_section_button_section {
    display: flex;
    /* background-color: red; */
    /* width: 80%; */
    /* flex-wrap: wrap; */
    align-items: center;
    /* margin: auto; */
    justify-content: center;
}

.get_started {
    background-color: #F4D313;
    padding: 10px 40px;
    border-radius: 28px;
    font-weight: 600;
    margin-right: 10px;
    /* font-size: 12px; */
}

.fa-solid.fa-play {
    padding: 140px 0px;
    background-color: red;
}

.watch_video {
    /* font-size: 1px; */
    margin-left: 10px;
    /* background-color: #ffffff; */
    padding: 10px 25px;
    border: 1px solid white;
    border-radius: 28px;
    font-weight: 600;
    cursor: pointer;
}

.curve {
    background: linear-gradient(#4E36E2);
    position: relative;
    /* border-radius: 50%; */
    /* height:40vh; */
    width: 100%;
}

.laptop {
    position: relative;
}

.laptop_model {
    position: absolute;
}

.laptop_screen {
    position: absolute;
    left: 0;
    top: 0;
    z-index: 10;
}

.circle {
    height: 50px;
    width: 50px;
    background-color: #ffffff;
    border-radius: 50%;
}



/* media queries */
@media (max-width: 726px) {
    .hero_section_content2 {
        /* font-size: 1rem; */
        padding: 24px 50px;
        width: 100%;
        font-size: 1.2rem;
        /* font-size: 10px; */
        /* letter-spacing: 0.6px; */
        text-align: justify;
    }
}


@media (max-width: 620px) {

    .hero_section_content {
        font-size: 32px;
        /* font-size: 2.5rem; */
    }

    .hero_section_content2 {
        /* font-size: 1rem; */
        padding: 24px 60px;
        width: 100%;
        font-weight: normal;
        /* letter-spacing: 0.6px; */
        text-align: justify;
    }

    .watch_video {
        margin-left: 0px;
        /* margin-top: 5px; */
        /* background-color: #ffffff; */
        /* padding: 10px 40px; */
        border: 1px solid white;
        border-radius: 28px;
    }
}

@media (max-width:414px) {
    .hero_section_content2 {
        /* background-color: red; */
        font-size: 15px;
        line-height: 35px;
    }
}

@media (max-width:330px) {

    .get_started,
    .watch_video {
        font-size: 10px;
    }
}

@media (max-width:305px) {

    .get_started,
    .watch_video {
        font-size: 9px;
    }
}

@media (max-width:294px) {

    .get_started,
    .watch_video {
        font-size: 8px;
    }
}