.main_container {
    /* display: flex; */
    margin: auto;
    align-items: center;
    position: relative;
    padding: 100px 0;
    /* background-color: red; */
    background: linear-gradient(180deg, rgba(49, 104, 236, 0.19) 0%,
            rgba(49, 104, 236, 0.06) 100%);
    z-index: 9;
}

.name_work_container {
    /* background-color: red; */
    padding: 0 20px;
}

.heading {
    text-align: center;
    font-size: 42px;
    font-weight: 600;
}

.subheading {
    text-align: center;
    font-size: 14px;
}

.slider_container {
    /* background-color: red; */
    margin: auto;
    display: flex;
    flex: wrap;
    justify-content: space-between;
    max-width: 80%;
    margin-top: 40px;
}

.main_box {
    background: rgba(247, 247, 247, 1);
    border-radius: 8px;
    padding: 32px 28px;
    width: 32%;
    /* background-color: red; */
    position: relative;
}

.main_box_white {
    border-radius: 8px;
    padding: 32px 28px;
    width: 32%;
    border: none;
    box-shadow: 0 1px 10px rgba(147, 153, 153, 0.726);
    background-color: white;
}

.para {
    padding: 40px 0;
    /* position: relative; */
    font-size: 14px;
}

.flex_container {
    /* background-color: red; */
    display: flex;
}

.name {
    font-size: 18px;
    color: #333333;
    font-weight: bold;
}

.profession {
    color: #4F4F4F;
    font-size: 14px;
    font-weight: 400;
}

.slider_left_button {
    position: absolute;
    z-index: 1;
    top: 60%;
    color: white;
    background-color: #2186F2;
    padding: 5px 10px;
    border-radius: 40px;
    /* left: 50%; */
    /* transform: translate(-50%, -50%); */
    left: 8.5%;
}

.slider_right_button {
    position: absolute;
    z-index: 1;
    top: 60%;
    border: 1px solid #2186F2;
    /* color: white; */
    color: #2186F2;
    padding: 5px 10px;
    border-radius: 40px;
    /* transform: translate(-50%, -50%); */
    right: 8.5%;
}

@media (max-width: 900px) {
    .main_box {
        display: none;
    }

    .main_box_white {
        border-radius: 8px;
        padding: 32px 28px;
        width: 100%;
    }

    .slider_right_button {
        right: 10px;
    }

    .slider_left_button {
        left: 50px;
    }

    .flex_container {
        /* background-color: red; */
        display: flex;
        /* justify-content: space-evenly; */
    }

    .name_work_container {
        /* background-color: red; */
        padding: 0 20px;
    }

    .name {
        font-size: 18px;
        color: #333333;
        font-weight: bold;
    }

    .profession {
        color: #4F4F4F;
        font-size: 14px;
        font-weight: 400;
    }
}

@media (max-width: 884px) {

    .slider_right_button {
        right: 10px;
    }

    .slider_left_button {
        left: 10px;
    }
}


@media (max-width: 550px) {
    .heading {
        font-size: 20px;
        /* text-align: left; */
    }

    .slider_right_button {
        right: 10px;
    }

    .slider_left_button {
        left: 10px;
    }


}