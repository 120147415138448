.container {
  width: 100%;
  height: 20vh;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  z-index: inherit;
  /* border: 10px solid lime; */
}

.curve {
  height: 70vh;
  /* position: relative; */
  overflow: hidden;
  z-index: 2;
  background: linear-gradient(#2186F2, #4E36E2, #4731d6);
  overflow: hidden;
  /* background-color: pink; */
  /* border: 10px solid grey; */
}

.white_curve {
  height: 55vh;
  z-index: 1;
  width: 100%;
  position: absolute;
  background-color: rgb(255, 255, 255);
  margin-bottom: 0px;
  margin: auto;
  margin-top: 250px;
  border-radius: 50% 50% 0 0 / 70px;
  /* background-color: red; */
}


.inner_container {
  /* width: 100%; */
  height: 20vh;
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  bottom: 10px;
  /* background-color: rgb(36, 23, 23); */
}

.mobileContainer {
  display: none;
}


.container img {
  width: 950px;
  margin-top: 100px;
  display: block;
  /* background-color: red; */
}

.inner_container img {
  width: 550px;
  display: block;
}




@media (max-width:977px) {
  .container img {
    width: 720px;
  }
}

@media (max-width:912px) {
  .curve {
    height: 70vh;
    overflow: hidden;
    z-index: 2;
    background: linear-gradient(#2186F2, #4E36E2, #4731d6);
  }
}

@media (max-width:726px) {
  .container img {
    width: 600px;
  }
}

@media (max-width:620px) {
  .container {
    display: none;
  }

  .white_curve {
    border-radius: 50% 50% 0 0 / 50px;
  }

  .mobileContainer {
    display: block;
    position: relative;
    margin-top: -250px;
    margin: auto;
    text-align: center;
    width: 300px;
    /* overflow: hidden; */
    z-index: 9870;
    /* background-color: lime; */
  }

  .mobileContainer img {
    width: 100%;
    padding: 15px;
    height: auto;
    margin-top: -150px;

  }


}


@media (max-width:410px) {
  .mobileContainer img {
    /* z-index: 50; */
    /* background-color: red; */
    width: 100%;
    /* height: auto; */
    padding: 48px;
    margin-top: -200px;
  }
}

@media (max-width:250px) {
  .mobileContainer img {
    /* z-index: 50; */
    /* background-color: red; */
    width: 100%;
    /* height: auto; */
    padding: 0px;
    margin-top: -200px;
  }

  .phone_base {
    /* background-color: red; */
  }
}