.footer {
    background: #211F25;
    /* background-color: red; */
    margin: auto;
    padding: 0px 140px;
    width: 100%;
}

form input {
    /* padding: 20px 0; */
    color: black;
    /* color: #F4D313; */
    /* color: #2186F2; */
}

.footer_bottom_section {
    font-size: 12px;
    color: #FFFFFF;
    padding: 30px 0 60px 0;
    border-top: 1px solid grey;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
}

.privacy_policy_section {
    display: flex;
}

.policy {
    padding: 0 10px 0 0;
    text-decoration: underline;
}

.footer_top_section {
    display: flex;
    flex-wrap: wrap;
    padding-bottom: 40px;
    /* background-color: red; */
    color: white;
    justify-content: space-between;
    align-items: center;
}

.logo_section {
    display: flex;
    align-items: center;
}

.navigation {
    display: flex;
    flex-wrap: wrap;
    padding: 20px 0 40px 0;
}

.navigation_items {
    padding: 0 10px;
    font-size: 14.41px;
}

.footer_email_section {
    /* width: 100%; */
    display: flex;
    flex-direction: column;
    padding: 20px 0px;
    /* justify-content: space-between; */
    /* background-color: red; */
}

.footer_email {
    font-size: 14px;
    padding: 14px 35px;
    border-radius: 28px;
    outline: none;
    margin-right: 40px;
    border: 1px solid #F4D313;
}

.footer_subscribe_button {
    font-size: 14px;
    padding: 14px 22px;
    border-radius: 28px;
    background-color: #F4D313;
    border: none;
    color: white;
}

/* query */

@media (max-width: 940px) {
    .footer {
        background: #211F25;
        margin: auto;
        padding: 20px 14px;
        width: 100%;
    }

    .navigation_items {
        padding: 0 10px;
        font-size: 20px;
        flex-direction: column;
    }

    .footer_email {
        font-size: 14px;
        padding: 2px 12px;
        border-radius: 28px;
        outline: none;
        /* width: max-content; */
        border: 1px solid #F4D313;
    }

    .footer_subscribe_button {
        font-size: 14px;
        padding: 2px 12px;
        border-radius: 28px;
        background-color: #F4D313;
        border: none;
        color: white;
    }

}

@media (max-width: 768px) {
    .footer_top_section {
        display: flex;
        text-align: left;
        flex-wrap: wrap;
        flex-direction: column;
    }
}

@media (max-width: 576px) {
    .footer_bottom_section {
        display: flex;
        flex-wrap: wrap;
        /* background-color: red; */
    }

    .policy {
        /* background-color: lime; */
        width: 100%;
        font-size: 15px;
        margin-bottom: 20px;
    }

    .privacy_policy_section {
        display: flex;
        flex-wrap: wrap;
        /* background-color: red; */
    }
}

@media (max-width:400px) {
    .footer_email_section {
        display: flex;
        flex-direction: column;
        padding: 20px 0;
    }

    .footer_email,
    .footer_subscribe_button {
        font-size: 14px;
        text-align: center;
        padding: 12px 25px;
        margin-top: 10px;
    }
    .footer_left {
        width: 100%;
        text-align: left;
        /* background-color: red; */
        padding: 0;
    }


    .navigation {
        text-align: left;
        flex-direction: column;
        display: flex;
        /* background-color: red; */
        flex-wrap: wrap;
        /* z-index:55; */
        padding: 20px 0 40px 0;
    }

    .navigation_items {
        padding: 20px 10px;
        font-size: 18px;
        border-bottom: 1px solid grey;
        /* background-color: red; */
    }

}